.nftPicDiv {
  margin-bottom: 5%;
}

.countdownMain{
  transform: translate(-50%, -50%);
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  top: 50%;
  left: 50%;
  z-index: 100000000000;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(15px);
-webkit-backdrop-filter: blur(15px);
background: rgb(0,0,0);
background: radial-gradient(circle, rgba(0,0,0,0.6830904236694677) 0%, rgba(0,31,16,0.4814097514005602) 50%, rgba(0,0,0,0.31894476540616246) 100%);
}


@media screen and (max-width: 499px) {


  .cs {
    font-family: 'Ubuntu', sans-serif;
    margin-top: 20px;
    color: white;
    font-size: 22px;
    color: white;
    text-shadow: 5px 12px 9px rgba(0,0,0,0.2), 0px -10px 40px rgba(255,255,255,0.3);
     font-family: 'Doctor Glitch', sans-serif;
     text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
     background: -webkit-linear-gradient( #d30bb8, #78f724);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
   }

  .dots {
    color: gray;
    display: flex;
    align-items: center;
  }

  .counterBlock {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .countdown {
    text-align: center;
    color: white;
    padding: 30px;
    font-size: 30px;
    text-align: center;
    color: white;
     transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    animation: popupAnimation 0.5s ease forwards;
  }

  .days {
    background-color: #a0ff0644;
    font-family: 'Doctor Glitch', sans-serif;
    border: 3px solid transparent;
     border-image: linear-gradient(90deg, #8c47fc, #65ff3f, #45ecf2, #f276d0) 3;
     border-image-slice: 1;
    border-left-color: white;
    border-top-color: white;
    padding: 10px;

     margin-right: 5px;
    margin-left: 5px;

  }

  .sec {
    animation: 1s 0.875s infinite timer_beat;
    background-color: #a0ff0644;
    font-family: 'Doctor Glitch', sans-serif;
    border: 3px solid transparent;
     border-image: linear-gradient(90deg, #8c47fc, #65ff3f, #45ecf2, #f276d0) 3;
     border-image-slice: 1; 
    border-left-color: white;
    border-top-color: white;
    padding: 10px;

     margin-left: 5px;

  }

  @keyframes timer_beat {
    0% {
      transform: scale(1.125);
    }

    50% {
      transform: none;
    }
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .cs {
    font-family: 'Ubuntu', sans-serif;
    margin-top: 25px;
    color: white;
     text-shadow: 5px 12px 9px rgba(0,0,0,0.2), 0px -10px 40px rgba(255,255,255,0.3);
     font-family: 'Doctor Glitch', sans-serif;
     font-size: 22px;
     text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
     background: -webkit-linear-gradient( #d30bb8, #78f724);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
  }

  .dots {
    color: gray;
    display: flex;
    align-items: center;
  }

  .counterBlock {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

  }

  .countdown {
    text-align: center;
    color: white;
    padding: 30px;
    font-size: 25px;
    margin-bottom: 20%;
    text-align: center;
    color: white;
     transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    animation: popupAnimation 0.5s ease forwards;
  }

  .days {

    background-color: #a0ff0644;
    font-family: 'Doctor Glitch', sans-serif;
    border: 3px solid transparent;
     border-image: linear-gradient(90deg, #8c47fc, #65ff3f, #45ecf2, #f276d0) 3;
     border-image-slice: 1;
    border-left-color: white;
    border-top-color: white;
    width: 100px;
    height: 100px;
    padding-top: 25px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    color: white;

     margin-right: 5px;
    margin-left: 5px;

  }

  .sec {
    animation: 1s 0.875s infinite timer_beat;
    background-color: #a0ff0644;
    font-family: 'Doctor Glitch', sans-serif;
    border: 3px solid transparent;
     border-image: linear-gradient(90deg, #8c47fc, #65ff3f, #45ecf2, #f276d0) 3;
     border-image-slice: 1; 
    border-left-color: white;
    border-top-color: white;
    width: 100px;
    height: 100px;
    padding-top: 25px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;

     margin-left: 5px;

  }

  @keyframes timer_beat {
    0% {
      transform: scale(1.125);
    }

    50% {
      transform: none;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .cs {
    font-family: 'Ubuntu', sans-serif;
    margin-top: 20px;
    color: white;
     color: white;
    text-shadow: 5px 12px 9px rgba(0,0,0,0.2), 0px -10px 40px rgba(255,255,255,0.3);
     font-family: 'Doctor Glitch', sans-serif;
     font-size: 30px;
     text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
     background: -webkit-linear-gradient( #d30bb8, #78f724);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
  }

  .dots {
    color: gray;
    display: flex;
    align-items: center;
  }

  .counterBlock {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;

  }

  .countdown {
    text-align: center;
    color: white;
    font-size: 60px;
    text-align: center;
    color: white;
     transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    animation: popupAnimation 0.5s ease forwards;
  }


  .dots {
    color: gray;
    display: flex;
    align-items: center;
  }

  .days {
    background-color: #a0ff0644;
    font-family: 'Doctor Glitch', sans-serif;
    border: 3px solid transparent;
     border-image: linear-gradient(90deg, #8c47fc, #65ff3f, #45ecf2, #f276d0) 3;
     border-image-slice: 1;
    border-left-color: rgb(255, 78, 78);
    border-top-color: rgb(255, 78, 78);
    width: 170px;
    height: 170px;
    padding-top: 40px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 25px;
    color: white;

     margin-right: 5px;
    margin-left: 5px;

  }

  .sec {
    animation: 1s 0.875s infinite timer_beat;
    background-color: #43bb25;
    font-family: 'Doctor Glitch', sans-serif;
    border: 3px solid transparent;
     border-image: linear-gradient(90deg, #8c47fc, #65ff3f, #45ecf2, #f276d0) 3;
     border-image-slice: 1; 
    border-left-color: white;
    border-top-color: white;
     width: 170px;
    height: 170px;
    padding-top: 40px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 25px;

     margin-left: 5px;

  }

  @keyframes timer_beat {
    0% {
      transform: scale(1.125);
    }

    50% {
      transform: none;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {


  .cs {
    font-family: 'Ubuntu', sans-serif;
    margin-top: 20px;
    color: white;
    text-shadow: 5px 12px 9px rgba(0,0,0,0.2), 0px -10px 40px rgba(255,255,255,0.3);
     font-family: 'Doctor Glitch', sans-serif;
     font-size: 40px;
     text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
     background: -webkit-linear-gradient( #d30bb8, #78f724);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
  }

  .dots {
    color: gray;
    display: flex;
    align-items: center;
  }

  .counterBlock {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .countdown {
    text-align: center;
    color: white;
    font-size: 60px;
    text-align: center;
    color: white;
     transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    animation: popupAnimation 0.5s ease forwards;
  }


  .dots {
    color: gray;
    display: flex;
    align-items: center;
  }

  .days {
    background-color: #a0ff0644;
    font-family: 'Doctor Glitch', sans-serif;
    border: 3px solid transparent;
     border-image: linear-gradient(90deg, #8c47fc, #65ff3f, #45ecf2, #f276d0) 3;
     border-image-slice: 1;
    border-left-color: white;
    border-top-color: white;
    width: 170px;
    height: 170px;
    padding-top: 40px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 25px;
    color: white;

     margin-right: 5px;
    margin-left: 5px;

  }

  .sec {
    animation: 1s 0.875s infinite timer_beat;
    background-color: #a0ff0644;
    font-family: 'Doctor Glitch', sans-serif;
    border: 3px solid transparent;
     border-image: linear-gradient(90deg, #8c47fc, #65ff3f, #45ecf2, #f276d0) 3;
     border-image-slice: 1; 
    border-left-color: white;
    border-top-color: white;
     width: 170px;
    height: 170px;
    padding-top: 40px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 25px;

     margin-left: 5px;

  }

  @keyframes timer_beat {
    0% {
      transform: scale(1.125);
    }

    50% {
      transform: none;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .cs {
    font-family: 'Ubuntu', sans-serif;
    margin-top: 20px;
    color: white;
    text-shadow: 5px 12px 9px rgba(0,0,0,0.2), 0px -10px 40px rgba(255,255,255,0.3);
     font-family: 'Doctor Glitch', sans-serif;
     font-size: 40px;
     text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
     background: -webkit-linear-gradient( #d30bb8, #78f724);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
   }

  .counterBlock {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .countdown {
    text-align: center;
    color: white;
    font-size: 60px;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    animation: popupAnimation 0.5s ease forwards;
   }

  .dots {
    color: gray;
    display: flex;
    align-items: center;
  }

  .days {
    background-color: #a0ff0644;
    font-family: 'Doctor Glitch', sans-serif;
     border: 3px solid transparent;
    border-image: linear-gradient(90deg, #8c47fc, #65ff3f, #45ecf2, #f276d0) 3;
    border-image-slice: 1;
    border-left-color: white;
    border-top-color: white;
    width: 170px;
    height: 170px;
    color: white;
     padding-top: 40px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 25px;
     margin-right: 5px;
    margin-left: 5px;

  }

  .sec {
    animation: 1s 0.875s infinite timer_beat;
    background-color: #a0ff0644;
     font-family: 'Doctor Glitch', sans-serif;
     border: 3px solid transparent;
     border-image: linear-gradient(90deg, #8c47fc, #65ff3f, #45ecf2, #f276d0) 3;
     border-image-slice: 1; 
     border-left-color: white;
     border-top-color: white;
    border-top-color: #fff;
    border-left-color: #fff;
      width: 170px;
    height: 170px;
    padding-top: 40px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 25px;

    margin-left: 5px;

  }

  @keyframes timer_beat {
    0% {
      transform: scale(1.125);
    }

    50% {
      transform: none;
    }
  }
}

@media screen and (min-width: 1920px) {

  .cs {
    font-family: 'Ubuntu', sans-serif;
    margin-top: 40px;
    text-shadow: 5px 12px 9px rgba(0,0,0,0.2), 0px -10px 40px rgba(255,255,255,0.3);
     font-family: 'Doctor Glitch', sans-serif;
     font-size: 55px;
     color: white;
     text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
     background: -webkit-linear-gradient( #d30bb8, #78f724);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
  }

  .dots {
    color: gray;
    display: flex;
    align-items: center;
  }

  .counterBlock {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

  }

  .countdown {
    text-align: center;
    color: white;
    font-size: 80px;
    text-align: center;
    color: white;
     transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow: hidden;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    animation: popupAnimation 0.5s ease forwards;
  }

  .days {

    background-color: #a0ff0644;
     font-family: 'Doctor Glitch', sans-serif;
     border: 6px solid transparent;
     border-image: linear-gradient(90deg, #8c47fc, #65ff3f, #45ecf2, #f276d0) 3;
     border-image-slice: 1;    width: 250px;
    height: 250px;
    padding-top: 67px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 25px;
    color: rgb(255, 255, 255);
    border-left-color: white;
    border-top-color: white;
     margin-right: 5px;
    margin-left: 5px;

  }

  .sec {
    animation: 1s 0.875s infinite timer_beat;
    background-color: #a0ff0644;
     font-family: 'Doctor Glitch', sans-serif;
     border: 6px solid transparent;
     border-image: linear-gradient(90deg, #8c47fc, #65ff3f, #45ecf2, #f276d0) 3;
     border-image-slice: 1; 
    width: 250px;
    height: 250px;
    padding-top: 67px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 25px;
    border-left-color: white;
    border-top-color: white;
     margin-left: 5px;

  }

  @keyframes timer_beat {
    0% {
      transform: scale(1.125);
    }

    50% {
      transform: none;
    }
  }
}

